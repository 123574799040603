import React from 'react'
import { Link } from 'react-router-dom';

export default function index() {
    return (
        <>
            <div className='left_menu_layout'>
                <h4>HTML Tutorial</h4>
                <ul>
                    <li><Link href='/demo'>Home</Link></li>
                    <li><Link href='/demo1'>Introduction</Link></li>
                </ul>
            </div>
        </>
    )
}
