import React from 'react';
import Header from '../component/Header';
import { Label } from 'reactstrap';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';

function Privacy(props) {
    const navigate = useNavigate();

    return (
        <div >
            <Header navigate={navigate} />
            <div className='container mt-5 privacy'>
                <h4 style={{marginTop:100}}>Privacy Policy</h4>
                <Label>Effective Date: 12-06-2023</Label>
                <p>This Privacy Policy describes how we collect, use, and disclose information when you use our mobile application and website for learning (the "Devsol" and "devsol.in"). By using the App and Website, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.</p>
                <h4>1. Information We Collect</h4>
                <p>1.1 Personal Information: We may collect personal information such as your name, email address, and other contact information when you create an account or voluntarily provide it to us.</p>
                <p>1.2 Usage Information: We collect information about how you interact with the App and Website, such as your IP address, device information, operating system, browser type, and pages you visit. We may use cookies, pixel tags, and similar technologies to collect this information.</p>
                <h4>2. Use of Information</h4>
                <p>2.1 Provide Services: We use the collected information to provide you with the services offered through the App and Website, including personalized learning experiences, progress tracking, and communication with instructors.</p>
                <p>2.2 Communication: We may use your contact information to send you important notices, updates, and promotional materials related to the App and Website. You can opt-out of receiving marketing communications by following the unsubscribe instructions provided in those communications.</p>
                <p>2.3 Improvements: We analyze usage information to improve the functionality and content of the App and Website, enhance user experience, and develop new features and offerings.</p>
                <h4>3. Sharing of Information</h4>
                <p>3.1 Service Providers: We may share your information with third-party service providers who assist us in operating the App and Website, such as hosting, data analysis, customer support, and email delivery.</p>
                <p>3.2 Legal Compliance: We may disclose your information to comply with applicable laws, regulations, legal processes, or governmental requests, or to protect our rights, privacy, safety, or property, or that of our users or the public.</p>
                <p>3.3 Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity or its agents as part of the due diligence process or ongoing operations.</p>
                <h4>4. Data Security</h4>
                <p>We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.</p>
                <h4>5. Children's Privacy</h4>
                <p>The App and Website are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected personal information from a child under 13, we will take reasonable steps to delete the information as soon as possible.</p>
                <h4>6. Changes to this Privacy Policy</h4>
                <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated Privacy Policy on the App and Website or by sending you an email. Please review this Privacy Policy periodically for any changes.</p>
                <h4>7. Contact Us</h4>
                <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <Link to="mailto:4developersolution@gmail.com">4developersolution@gmail.com.</Link></p>
            </div>
        </div>
    )
}

export default Privacy;