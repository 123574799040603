import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap';
import Header from '../component/Header';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

export default function Html() {

    useEffect(() => {
        // console.log("adsbygoogle", window?.adsbygoogle);
        window.scroll(0, 0);
        if (window.adsbygoogle) {
            if (!window?.adsbygoogle?.loaded)
                window.adsbygoogle.push({});
        }
    }, []);

    const handleCopy = (to) => {
        if (to == 1) {
            const textToCopy = `<!DOCTYPE html>
<html>
    <head>
        <title>My First HTML Page</title>
    </head>
    <body>
        <h1>Welcome to My First HTML Page</h1>
        <p>This is a paragraph of text on my webpage.</p>
    </body>
</html>`;
            navigator.clipboard.writeText(textToCopy).then(
                () => {
                    console.log('Text copied to clipboard successfully!');
                    toast.success("Copied!");
                },
                (err) => {
                    console.error('Failed to copy text: ', err);
                    toast.error("Something Went Wrong!");
                }
            );

        }
    }

    return (
        <React.Fragment>
            {/* <Header /> */}
            <Helmet>
                <meta name="title" content="Understanding HTML: The Foundation of Web Development" />
                <meta name="description" content="Learn about HTML, its uses, requirements, and a step-by-step guide on creating and running your first HTML file. Start your web development journey today!" />
                <meta name="keywords" content="HTML, web development, create a website, how to start HTML, HTML tutorial, web design, HTML basics, learning HTML, HTML guide" />
                <meta name="author" content="Devsol" />
                <meta property="og:title" content="Understanding HTML: The Foundation of Web Development" />
                <meta property="og:description" content="Discover how to create a website using HTML. Learn the basics of HTML, the requirements, and step-by-step instructions on how to start your web development journey." />
                <meta property="og:type" content="article" />
                <meta property="og:url" content="https://devsol.in/blog/html" />
                <meta property="og:image" content="https://devsol.in/image/html.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Understanding HTML: The Foundation of Web Development" />
                <meta name="twitter:description" content="Learn the fundamentals of HTML and how to create and run your first HTML file. Start your journey in web development with our comprehensive guide." />
                <meta name="twitter:image" content="https://devsol.in/image/html.jpg" />
                <title>Understanding HTML: The Foundation of Web Development</title>
            </Helmet>
            <div className='main'>
                <section className='section section-1 px-3'>
                    <div className='container-fluid'>
                        <Row>
                            <Col md={2} style={{}}>
                                <div style={{ margin: "20px 0", textAlign: "center" }}>
                                    <ins className="adsbygoogle"
                                        style={{ display: 'block' }}
                                        data-ad-client="ca-pub-3940256099942544"
                                        data-ad-slot="1234567890"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                </div>
                            </Col>
                            <Col md={8} style={{}}>
                                <h2 className='heading'>Understanding HTML: <span>The Foundation of Web Development</span></h2>
                                <Row className='mt-5'>
                                    <Col md={8} className='blog-content'>
                                        <h3 >What is HTML?</h3>
                                        <p className='mt-2'>
                                            HTML, or HyperText Markup Language, is the standard language used to create and design webpages and web applications.
                                            It provides the structure of a webpage, allowing you to define elements such as headings, paragraphs, links, images, and more.
                                            HTML is essential for anyone looking to build a website or web application, as it forms the backbone of all web content.
                                        </p>
                                        <h3 className='mt-4'>Requirements for Starting with HTML</h3>
                                        <p >To start coding in HTML, you'll need the following:</p>
                                    </Col>
                                    <Col md={4}>
                                        <img src={"https://devsol.in/image/html.jpg"} style={{ width: '100%', height: 'auto' }} />
                                    </Col>
                                    <Col md={12} className='blog-content'>
                                        <h5 className='mt-4'>1. A Computer -</h5>
                                        <p className='p1'>⦿ Whether it's a laptop or desktop, you'll need a computer to write and test your HTML code.</p>
                                        <h5 className='mt-4'>2. A Text Editor -</h5>
                                        <p className='p1'>⦿ You can use any text editor to write HTML code. Some popular options include:</p>
                                        <p className='p2'>⦿ <b>Notepad (Windows) :</b> A simple text editor that comes pre-installed on Windows.</p>
                                        <p className='p2'>⦿ <b>TextEdit (Mac) :</b> A basic text editor available on macOS.</p>
                                        <p className='p2'>⦿ <b>Visual Studio Code :</b> A powerful, free code editor with many features to aid in development.</p>
                                        <p className='p2'>⦿ <b>Sublime Text :</b> A lightweight, fast, and customizable text editor.</p>
                                        <h5 className='mt-4'>2. A Web Browser -</h5>
                                        <p className='p1'>⦿ To view and test your HTML files, you'll need a web browser. Popular options include Google Chrome, Mozilla Firefox, Safari, and Microsoft Edge.</p>
                                    </Col>
                                    <Col md={12} className='blog-content'>
                                        <h3 className='mt-4 mb-4'>Creating Your First HTML File</h3>
                                        <h5>Step 1 : Open Your Text Editor</h5>
                                        <p className=''>Open your preferred text editor to start writing your HTML code. For this example, we'll use a basic text editor like Notepad or TextEdit.</p>
                                        <h5 className='mt-4 mb-2'>Step 2 : Write Basic HTML Structure</h5>
                                        <p className=''>In your text editor, type the following basic HTML code:</p>
                                    </Col>
                                    <Col md={12} className='mt-4'>
                                        <div className="try_it-code">
                                            <Row>
                                                <Col md={10}>
                                                    <h5 className='mb-0'>HTML</h5>
                                                </Col>
                                                <Col md={2} className='d-flex justify-content-end'>
                                                    <i className="fas fa-copy" style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => handleCopy(1)}> Copy Code</i>
                                                </Col>
                                            </Row>
                                            <hr style={{ borderColor: 'white' }}></hr>
                                            {`<!DOCTYPE html>`}<br />
                                            {`<html>`}<br />
                                            <span className='ml1'>{`<head>`}</span><br />
                                            <span className='ml1 ml2'>{`<title>My First HTML Page</title>`}</span><br />
                                            <span className='ml1'>{`</head>`}</span><br />
                                            <span className='ml1'>{`<body>`}</span><br />
                                            <span className='ml1 ml2'>{`<h1>Welcome to My First HTML Page</h1>`}</span><br />
                                            <span className='ml1 ml2'>{`<p>This is a paragraph of text on my webpage.</p>`}</span><br />
                                            <span className='ml1'>{`</body>`}</span><br />
                                            {`</html>`}<br />
                                        </div>
                                    </Col>
                                    <Col md={12} className='blog-content mt-4'>
                                        <p>This code includes the essential elements of an HTML document:</p>
                                        <p className='p1'>⦿ <b>{`<!DOCTYPE html>`} :</b> Declares the document type and version of HTML.</p>
                                        <p className='p1'>⦿ <b>{`<html>`} :</b> The root element that contains all other HTML elements.</p>
                                        <p className='p1'>⦿ <b>{`<head>`} :</b> Contains meta-information about the document, such as the title.</p>
                                        <p className='p1'>⦿ <b>{`<title>`} :</b> Sets the title of the webpage, which appears in the browser tab.</p>
                                        <p className='p1'>⦿ <b>{`</head>`} :</b> Closing tag of head.</p>
                                        <p className='p1'>⦿ <b>{`<body>`} :</b> Contains the content of the webpage, including headings, paragraphs, and other elements.</p>
                                        <p className='p1'>⦿ <b>{`<h1>`} :</b> Top-level Heading tag, Basically they are 6 types from H1 to H6. H1 is the bigest one and h6 is smallest one.</p>
                                        <p className='p1'>⦿ <b>{`</h1>`} :</b> Closing tag for H1.</p>
                                        <p className='p1'>⦿ <b>{`<p>`} :</b> Paragraph Tag, Paragraphs can be added in it.</p>
                                        <p className='p1'>⦿ <b>{`</p>`} :</b> Closing tag for paragraph.</p>
                                        <p className='p1'>⦿ <b>{`</body>`} :</b> Closing tag of body.</p>
                                        <p className='p1'>⦿ <b>{`</html>`} :</b> Closing tag of html.</p>
                                    </Col>
                                    <Col md={12} className='blog-content'>
                                        <h5 className='mt-4 mb-2'>Step 3 : Save the HTML File</h5>
                                        <p>Save the file with an <span className='code'>.html</span> extension. For example, you can save it as <span className='code'>index.html</span>.</p>
                                        <p className='p1'>⦿ <b>Notepad (Windows) :</b></p>
                                        <p className='p2'>⦿ Click <span className='code'> File </span> {`>`} <span className='code'> Save As</span>.</p>
                                        <p className='p2'>⦿ Choose a location to save the file.</p>
                                        <p className='p2'>⦿ In the "Save as type" dropdown, select "All Files".</p>
                                        <p className='p2'>⦿ Enter <span className='code'>index.html</span> as the filename and click <span className='code'>Save</span>.</p>
                                        <p className='p1'>⦿ <b>TextEdit (Mac) :</b></p>
                                        <p className='p2'>⦿ Click <span className='code'> File </span> {`>`} <span className='code'> Save</span>.</p>
                                        <p className='p2'>⦿ Choose a location to save the file.</p>
                                        <p className='p2'>⦿ Enter <span className='code'>index.html</span> as the filename and click <span className='code'>Save</span>.</p>
                                    </Col>
                                    <Col md={12} className='blog-content'>
                                        <h5 className='mt-4 mb-2'>Step 4 : Open the HTML File in a Web Browser</h5>
                                        <p className='mt-3'>To view your HTML file, open your web browser and do the following:</p>
                                        <p className='p1'>⦿ Press Ctrl + O (Windows) or Command + O (Mac) to open a file.</p>
                                        <p className='p1'>⦿ Browse to the location where you saved the index.html file and open it.</p>
                                        <p>You should see a webpage displaying "Welcome to My First HTML Page" as the heading and a paragraph of text.</p>
                                    </Col>
                                    <Col md={12} className='mt-4 mb-2 blog-content'>
                                        <h3>Conclusion</h3>
                                        <p>HTML is the foundation of web development, providing the structure for webpages and web applications.
                                            With a computer, text editor, and web browser, you can start creating your own HTML files and building your first webpages.
                                            By understanding the basic elements of HTML, you can begin your journey into the world of web development.</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} style={{}}>

                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}
