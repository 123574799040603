import './App.css';
import './assets/css/style.css'
import './assets/css/clash-display.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Router from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Header from './pages/component/Header';

function App() {
  return (
    <div className="wrapper">
      <ToastContainer />
      <Router />
      {/* <Header />
    <Home /> */}
    </div>
  );
}

export default App;
