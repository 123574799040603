import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';


export default function Header() {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState("");

    useEffect(() => {
        if (location?.pathname) {
            if (location?.pathname.split("/")?.length > 0)
                setCurrentPage(location?.pathname.split("/")[1]);
        }
    }, [location.pathname]);

    console.log('location on header', currentPage);


    return (
        <header className='p-l-r'>
            <nav className="navbar navbar-expand-lg navbar-light bg_light_bg">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <div className='logo_img'>
                            <img src={require('../../assets/images/logo.png')} />
                        </div>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            {currentPage &&
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                                </li>
                            }
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/blogs">Blogs</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/product">Product</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/privacy">Privacy-Policy</Link>
                            </li>

                            {/* <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Home</a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                            </li> */}
                        </ul>
                        <div className='authbox'>
                            {/* <ul>
                                <li>
                                    <a href='#' className='custom-btn btn-12'>
                                        <span>Sign In</span><span>Login</span>
                                    </a>

                                </li>
                                <li>
                                <a href='#' className='custom-btn btn-12'>
                                    <span>Sign Up</span><span>Register</span>
                                    </a>
                                </li>
                            </ul> */}
                            <ul className="icons">
                                <li><Link to="https://www.facebook.com/devsolctrla/" target='_blank'><i><FiFacebook /></i></Link></li>
                                <li><Link to="https://www.instagram.com/devs_ol/" target='_blank'><i><FiInstagram /></i></Link></li>
                                <li><Link to="https://www.linkedin.com/company/dev4sol/" target='_blank'><i><FiLinkedin /></i></Link></li>
                            </ul>
                        </div>
                        {/* <form className="d-flex">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form> */}
                    </div>
                </div>
            </nav>
        </header>
    )
}
