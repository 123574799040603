import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Route404 from '../pages/Route404';
import Privacy from "../pages/Privacy";
import Product from "../pages/Product";
import Html from "../pages/Blogs/Html";
import Blog from "../pages/Blogs";
import Demo from "../pages/Home/Demo";
import Demo1 from "../pages/Home/Demo1";

export default function Router(props) {
    console.log('props', props)
    return (
        <div >
            <BrowserRouter>
                {/* <Header /> */}
                <Routes>

                    {/* <Route path="/" element={<Dashboard />} /> */}
                    <Route path="/" element={<Home />} />
                    {/* <Route path="/html" element={<Demo />} /> */}
                    <Route path="/html" element={<Demo1 />} />
                    {/* <Route path="/courses" element={<Course />} /> */}
                    {/* <Route path="/courses/:id/:name" element={< CourseLession />} /> */}
                    {/* <Route path="/code-view" element={< CodeView />} /> */}
                    {/* <Route path="/devsol" element={<Devsol />} /> */}
                    {/* <Route path="/devsol/detail/:question" element={<DevDetail />} /> */}
                    <Route path="/blogs" element={<Blog />} />
                    <Route path="/blog/html" element={<Html />} />
                    <Route path="/product" element={<Product />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="*" element={<Route404 />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}