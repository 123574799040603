import React from 'react';
import Header from '../component/Header';
import Lottie from "lottie-react";
import Footer from '../component/Footer';

export default function Route404() {

    return (
        <React.Fragment>
            <Header />
            <div className='banner-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='banner-content'>
                                <h1>Learn to<br /><span>{'<Code />'}</span>.<br />For everyone.</h1>
                                <p>Unlock the world of coding with our comprehensive learning platform. Master programming languages, tackle challenging problems, and connect with a thriving community of developers. Find freelancing projects, showcase your skills, and collaborate with clients. Join our coding community today and embark on a rewarding journey in the world of programming.</p>
                                <a href='#' className='custom-btn btn-12'>
                                    {/* <span>Courses</span> <span>Get Started</span> */}
                                    <span>Waiting...</span> <span>Coming Soon</span>
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='banner-right-content'>
                                <div style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '85vh' }}>
                                    <Lottie animationData={require('../../assets/lotti/404.json')} loop={true} autoPlay
                                        style={{ height: '85vh', width: '100vh' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}