import React from 'react'
import '../../assets/css/innerStyle.css'
import '../../assets/css/common.css'
import Footer from '../component/Footer'
import HtmlLeft from '../component/layout/sidebar/html';
import Header from '../component/Header';
export default function Demo1() {

    return (
        <>
            <Header />
            <div className="main-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2 p-0">
                            <HtmlLeft />
                        </div>
                        <div className="col-lg-10 p-0 m-0">
                            <div className='right-container'>
                                <div className="right-lession-section">
                                    <div className="lession-header">
                                        <h1>HTML Introduction</h1>
                                        <div className="header-btn-section">
                                            <a href="#" className="left_arrow">
                                                <img src={require('../../assets/images/left-arrow.png')} />
                                            </a>
                                            <a href="#" className="right_arrow">
                                                <img src={require('../../assets/images/left-arrow.png')} />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='small_des'>
                                        <p className='paragraph1'>HTML is the standard markup language for creating Web pages.</p>
                                    </div>
                                    <div className="description-section mt-5">

                                        <h2>What is HTML?</h2>
                                        <ul className='list-one'>
                                            <li>HTML stands for Hyper Text Markup Language</li>
                                            <li>HTML is the standard markup language for creating Web pages</li>
                                            <li>HTML describes the structure of a Web page</li>
                                            <li>HTML consists of a series of elements</li>
                                            <li>HTML elements tell the browser how to display the content</li>
                                            <li>HTML elements label pieces of content such as "this is a heading", "this is a paragraph", "this is a link", etc.</li>
                                        </ul>
                                        <hr className='divider_line' />
                                        <h3>A Simple HTML Document</h3>
                                        <div className="try-it-section">
                                            <div className="try-it-section-header">
                                                <h5>Example</h5>
                                                <a href="#">Try it Yourself</a>
                                            </div>
                                            <div className="try_it-code">
                                                {`<h1>This is a Heading</h1>`}<br />
                                                {`<h1>This is a Heading</h1>`}<br />
                                                {`<h1>This is a Heading</h1>`}<br />
                                                {`<h1>This is a Heading</h1>`}<br />
                                                {`<h1>This is a Heading</h1>`}<br />
                                                {`<h1>This is a Heading</h1>`}<br />
                                                {`<h1>This is a Heading</h1>`}<br />
                                                {`<h1>This is a Heading</h1>`}<br />

                                            </div>
                                        </div>
                                        <h4>Example Explained</h4>
                                        <ul className='list-one'>
                                            <li>The <code className='span1'>{'<!DOCTYPE html>'}</code> declaration defines that this document is an HTML5 document</li>
                                            <li>The <code className='span1'>{'<!DOCTYPE html>'}</code> declaration defines that this document is an HTML5 document</li>
                                            <li>The <code className='span1'>{'<!DOCTYPE html>'}</code> declaration defines that this document is an HTML5 document</li>
                                            <li>The <code className='span1'>{'<!DOCTYPE html>'}</code> declaration defines that this document is an HTML5 document</li>
                                            <li>The <code className='span1'>{'<!DOCTYPE html>'}</code> declaration defines that this document is an HTML5 document</li>
                                            <li>The <code className='span1'>{'<!DOCTYPE html>'}</code> declaration defines that this document is an HTML5 document</li>
                                            <li>The <code className='span1'>{'<!DOCTYPE html>'}</code> declaration defines that this document is an HTML5 document</li>
                                        </ul>
                                        <hr className='divider_line' />
                                        <h3>What is an HTML Element?</h3>
                                        <p className='paragraph1'>An HTML element is defined by a start tag, some content, and an end tag:</p>
                                        <h6 className='tagname py-3'>{`<tagname>`} Content goes here... {`</tagname>`}</h6>
                                        <p className='paragraph1'>The HTML <code className='span1'>element</code> is everything from the start tag to the end tag:</p>
                                        <h2 className='py-3'>{'<h1>My First Heading</h1>'}</h2>
                                        <h4>{'<p>My first paragraph.</p>'}</h4>
                                        <table class="table table-bordered table-one">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Start tag</th>
                                                    <th scope="col">Element content</th>
                                                    <th scope="col">End tag</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{'<h1>'}</td>
                                                    <td>My First Heading</td>
                                                    <td>{'</h1>'}</td>
                                                </tr>
                                                <tr>
                                                    <td>{'<p>'}</td>
                                                    <td>My first paragraph.</td>
                                                    <td>{'</p>'}</td>
                                                </tr>
                                                <tr>
                                                    <td>{'<br>'}</td>
                                                    <td>none</td>
                                                    <td>none</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <div className='note1'>
                                            <strong>Note:</strong> Some HTML elements have no content (like the {'<br/>'} element). These elements are called empty elements. Empty elements do not have an end tag!
                                        </div>
                                        <hr className='divider_line' />
                                        <h3>Web Browsers</h3>
                                        <p className='paragraph1'>The purpose of a web browser (Chrome, Edge, Firefox, Safari) is to read HTML documents and display them correctly.</p>
                                        <p className='paragraph1'>A browser does not display the HTML tags, but uses them to determine how to display the document:</p>
                                        <div className='example_img mt-4 mb-4'>
                                            <img src={require('../../assets/images/img_chrome.png')} />
                                        </div>
                                        <h4>HTML Page Structure</h4>
                                        <p className='paragraph1'>Below is a visualization of an HTML page structure:</p>
                                        <div className='pageStructure'>
                                            <div className='html_frame'>
                                                <p className='paragraph1'>{"<html>"}</p>
                                                <div className='head_frame'>
                                                    <p className='paragraph1'>{"<head>"}</p>
                                                    <div className='title_frame'>
                                                        <p className='paragraph1'>{"<title>Page title</title>"}</p>
                                                    </div>
                                                    <p className='paragraph1'>{"</head>"}</p>
                                                </div>
                                                <div className='body_frame'>
                                                    <p className='paragraph1'>{"<body>"}</p>
                                                    <div className='body_content_frame'>
                                                        <div className='body_content_framei'>
                                                            {"<h1>This is a heading</h1>"}
                                                        </div>
                                                        <div className='body_content_framei'>
                                                            {"<p>This is a paragraph.</p>"}
                                                        </div>
                                                        <div className='body_content_framei'>
                                                            {"<p>This is another paragraph.</p>"}
                                                        </div>
                                                    </div>
                                                    <p className='paragraph1'>{"</body>"}</p>
                                                </div>
                                                <p className='paragraph1'>{"</html>"}</p>
                                            </div>
                                        </div>
                                        <div className='note1'>
                                            <strong>Note:</strong> The content inside the {"<body>"} section will be displayed in a browser. The content inside the {"<title>"} element will be shown in the browser's title bar or in the page's tab.
                                        </div>
                                        <hr className='divider_line' />
                                        <h3>HTML History</h3>
                                        <p className='paragraph1'>Since the early days of the World Wide Web, there have been many versions of HTML:</p>
                                        <table class="table table-bordered table-one">
                                            <thead>
                                                <tr>
                                                    <th>Year</th>
                                                    <th>Version</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1989</td>
                                                    <td>Tim Berners-Lee invented www</td>
                                                </tr>
                                                <tr>
                                                    <td>1991</td>
                                                    <td>Tim Berners-Lee invented HTML</td>
                                                </tr>
                                                <tr>
                                                    <td>1993</td>
                                                    <td>Dave Raggett drafted HTML+</td>
                                                </tr>
                                                <tr>
                                                    <td>1995</td>
                                                    <td>HTML Working Group defined HTML 2.0</td>
                                                </tr>
                                                <tr>
                                                    <td>1997</td>
                                                    <td>W3C Recommendation: HTML 3.2</td>
                                                </tr>
                                                <tr>
                                                    <td>1999</td>
                                                    <td>W3C Recommendation: HTML 4.01</td>
                                                </tr>
                                                <tr>
                                                    <td>2000</td>
                                                    <td>W3C Recommendation: XHTML 1.0</td>
                                                </tr>
                                                <tr>
                                                    <td>2008</td>
                                                    <td>WHATWG HTML5 First Public Draft</td>
                                                </tr>
                                                <tr>
                                                    <td>2012</td>
                                                    <td>WHATWG HTML5 Living Standard</td>
                                                </tr>
                                                <tr>
                                                    <td>2014</td>
                                                    <td>W3C Recommendation: HTML5</td>
                                                </tr>
                                                <tr>
                                                    <td>2016</td>
                                                    <td>W3C Candidate Recommendation: HTML 5.1</td>
                                                </tr>
                                                <tr>
                                                    <td>2017</td>
                                                    <td>W3C Recommendation: HTML5.1 2nd Edition</td>
                                                </tr>
                                                <tr>
                                                    <td>2017</td>
                                                    <td>W3C Recommendation: HTML5.2</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='note1'>
                                            <p className='paragraph1'>This tutorial follows the latest HTML5 standard.</p>
                                        </div>
                                        <div className="header-btn-section header-btn-section-footer">
                                            <a href="#" className="left_arrow">
                                                <img src={require('../../assets/images/left-arrow.png')} />
                                            </a>
                                            <a href="#" className="right_arrow">
                                                <img src={require('../../assets/images/left-arrow.png')} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="add_section">

                                </div>
                            </div>
                            <Footer />
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
