import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Link, useNavigate, useNavigation } from "react-router-dom";
import './Home.css';
import Header from '../component/Header';
// import Footer from '../../component/Footer/Footer';
import { FiMessageSquare, FiX } from 'react-icons/fi';
// import { AiOutlineSend } from 'react-icons/ai';
import * as Utils from '../Utils';
import Footer from '../component/Footer';
// import Login from "../../component/Login";
// import { useSelector, useDispatch } from 'react-redux';
// import { setUser } from '../../component/store/user';
// import Lottie from "lottie-react";

export default function Product(props) {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const user = useSelector((state) => state.user?.data);
  const [loading, setLoading] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  const [blogs, setBlogs] = useState([
    {
      _id: 1,
      title: 'Why Coding ?',
      desc: "Learning to code is becoming increasingly important in today's world, where technology is constantly advancing and transforming the way we live and work. Whether you're interested in building websites, mobile apps, or working in the field of artificial intelligence, coding is a valuable skill to have. In this blog, we'll discuss the benefits of learning to code and some tips for getting started."
    },
    {
      _id: 2,
      title: 'Career opportunities',
      desc: "The demand for coding skills is high, and it's only going to increase in the future. Many industries are looking for skilled developers, from startups to tech giants like Google and Microsoft. Learning to code can open up many job opportunities, and it can also be a valuable asset if you're looking to start your own business."
    },
    {
      _id: 3,
      title: 'Problem-solving skills',
      desc: "Coding involves breaking down complex problems into smaller, more manageable tasks. This process can help you develop problem-solving skills that can be applied to many different areas of life."
    },
    {
      _id: 4,
      title: 'Creativity',
      desc: "Coding is a creative process that allows you to bring your ideas to life. Whether you're building a website or an app, coding gives you the ability to create something that is unique and functional."
    },
    {
      _id: 2,
      title: 'Getting started: Choose a language',
      desc: "There are many programming languages to choose from, so it's important to choose one that's right for you. Some popular languages include Python, Java, JavaScript, and Ruby. Each language has its own strengths and weaknesses, so do some research to determine which language is best suited for your needs."
    },
    {
      _id: 3,
      title: 'Find resources',
      desc: "There are many resources available for learning to code, from online tutorials to in-person classes. Some popular websites for learning to code include Codecademy, Udemy, and Coursera. These resources offer a range of courses, from beginner to advanced levels."
    },
    {
      _id: 4,
      title: 'Practice',
      desc: "Practice is essential for mastering any skill, and coding is no exception. Try to code every day, even if it's just for a few minutes. This will help you build your skills and keep your knowledge up-to-date."
    },
    {
      _id: 5,
      title: 'Build something',
      desc: "Building a project is a great way to apply your coding skills and get real-world experience. Start small, and work your way up to more complex projects. This will help you build your portfolio and demonstrate your skills to potential employers."
    },
  ]);
  const [isTurnedOn, setIsTurnOn] = useState(true);
  const [isLogin, setIsLogin] = useState(false);

  const onLoginClick = () => {
    if (isTurnedOn) {
      setIsTurnOn(!isTurnedOn);
      setTimeout(() => {
        setIsLogin(!isLogin);
      }, 500);
    } else {
      setIsLogin(!isLogin);
      setTimeout(() => {
        setIsTurnOn(!isTurnedOn);
      }, 500);
    }
  }

  // useEffect(() => {
  //   if (user) {
  //     setIsLogin(false);
  //     setIsTurnOn(true);
  //   }
  // }, [user]);

  return (
    <div>
      <Header onLoginClick={onLoginClick} navigate={navigate} />
      <Container className='wrapper'>
        {/* <Anim/> */}
        <div class="sky">
          <div class="stars"></div>
          <div class="stars1"></div>
          <div class="stars2"></div>
          <div class="shooting_stars"></div>
        </div>
        <Row>
          <Col lg={6} md={6} style={{ alignSelf: 'center', marginTop:30 }}>
            <div className='product'>
              {/* <div className='cat_img'> */}
              {/* <Lottie animationData={require('../../assets/lotti/learn.json')} loop={true} autoPlay
                style={{ height: 140, width: 212 }}
              /> */}
              {/* </div> */}
              <div className='image-container'>
                <img src={require('../../assets/images/logo.webp')} className='product_img' alt="" />
              </div>
              <div className='box-type-head-home'>
                <Link to={"https://devsol.in/hanuman-chalisa"} target="_blank" hint='' className='link_with_title'>
                  <h1>Hanuman Chalisa</h1>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} style={{ alignSelf: 'center' }}>
            <div className='product'>
              <div className='image-container'>
                <img src={require('../../assets/images/mahakaal.webp')} className='product_img' alt="" />
              </div>
              {/* <Lottie animationData={require('../../assets/lotti/devsol.json')} loop={true} autoPlay
                style={{ height: 167, width: 212 }}
              /> */}
              <div className='box-type-head-home'>
                <Link to={"https://play.google.com/store/apps/details?id=com.goldy.mahakaal"} target="_blank" hint='' className='link_solbox'>
                  <h1>Mahakaal</h1>
                </Link>
              </div>
            </div>
          </Col>
          {/* <Col lg={6} md={6}>
            <div className={isTurnedOn ? 'tv_effect active' : 'tv_effect'}>
              <div className='second_sec'>
                {blogs?.map((item) => (
                  <div className='blog'>
                    <span>
                      <h2>{item.title}</h2>
                    </span>
                    <span>
                      <p>{item?.desc}</p>
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className={isLogin ? 'tv_effect active' : 'tv_effect'}>
              <div className='second_sec'>
                <Login setLoading={setLoading} />
              </div>
            </div>
          </Col> */}
        </Row>
        {/* <div className={isSupport ? 'support-box active' : "support-box"} style={{ color: Utils.themeColor }}>
          <div className='support-form'>
            <label>Need Help ?</label>
            <form>
              <input
                type="text"
                name="name"
                required
                label="Name"
                placeHolder="Enter your full name"
              />
            </form>
          </div>
        </div>
        <button type="button" className='sticky-button' style={{ backgroundColor: Utils.themeColor }} onClick={() => setIsSupport(!isSupport)}>
          {isSupport ?
            <FiX size={26} />
            :
            <FiMessageSquare size={24} className='message_icon' />
          }
        </button> */}
      </Container>
      {/* <Footer /> */}
    </div>
  )
}
