import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import Header from '../component/Header';
import { Link } from 'react-router-dom';
import { FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi';
import Footer from '../component/Footer';
import * as Utils from "../Utils";
import { Helmet } from "react-helmet";

export default function Home() {
  const COLORS = ['#bbf7d0', '#99f6e4', '#bfdbfe', '#ddd6fe', '#f5d0fe', '#fed7aa', '#fee2e2'];
  const TAGS = ['HTML', 'CSS', 'JavaScript', 'Typescript', 'Tailwind', 'React', 'Next.js', 'Gatsby', 'UI/UX', 'SVG', 'animation', 'webdev'];
  const DURATION = 15000;
  const ROWS = 6;
  const TAGS_PER_ROW = 8;

  const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
  const shuffle = (arr) => [...arr].sort(() => .5 - Math.random());

  const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
    return (
      <div className='loop-slider' style={{
        '--duration': `${duration}ms`,
        '--direction': reverse ? 'reverse' : 'normal'
      }}>
        <div className='inner'>
          {children}
          {children}
        </div>
      </div>
    );
  };

  const Tag = ({ text }) => (
    <div className='tag'><span style={{ color: Utils.textColor }}>#</span> {text}</div>
  );
  return (
    <>
      <Header />
      <Helmet>
        <title id="pageTitle">Devsol | Learn Coding Languages Online</title>
        <link rel="canonical" href="https://www.devsol.in/blogs" />
        <meta name="description"
          content="Unlock the world of coding with our comprehensive learning platform. Master programming languages, tackle challenging problems, and connect with a thriving community of developers. Find freelancing projects, showcase your skills, and collaborate with clients. Join our coding community today and embark on a rewarding journey in the world of programming." />
        <meta name="keywords"
          content="HTML, tutorial, beginner, advanced, web development, coding, programming, learning platform, coding community, problem-solving, freelancing projects, freelance opportunities, programming languages, coding challenges, collaborative coding, coding tutorials, coding resources, coding forums, coding help, coding projects, coding marketplace" />
        <meta name="author" content="Devsol" />
      </Helmet>
      <div className='banner-section p-l-r'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='banner-content'>
                <h1>Learn to<br /><span>{'<Code />'}</span>.<br />For everyone.</h1>
                <p>Unlock the world of coding with our comprehensive learning platform. Master programming languages, tackle challenging problems, and connect with a thriving community of developers. Find freelancing projects, showcase your skills, and collaborate with clients. Join our coding community today and embark on a rewarding journey in the world of programming.</p>
                <a href='#' className='custom-btn btn-12'>
                  {/* <span>Courses</span> <span>Get Started</span> */}
                  <span>Waiting...</span> <span>Coming Soon</span>
                </a>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='banner-right-content'>
                <div className='tag-list'>
                  {[...new Array(ROWS)].map((_, i) => (
                    <InfiniteLoopSlider key={i} duration={random(DURATION - 5000, DURATION + 5000)} reverse={i % 2}>
                      {shuffle(TAGS).slice(0, TAGS_PER_ROW).map(tag => (
                        <Tag text={tag} key={tag} />
                      ))}
                    </InfiniteLoopSlider>
                  ))}
                  <div className='fadeBox' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='section section-1'>
        <div className='shadow_1'>
          <img src={require('../../assets/images/shadow.png')} />
        </div>
        <h2 className='heading'>Tech <span>Blogs</span></h2>
        <div className='slider-section-blog'>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3.2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 20,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <Link to={"/blog/html"} className='td-none'>
                <div className='course_card'>
                  <div className='img_box'>
                    <img src={require('../../assets/images/html.jpg')} />
                  </div>
                  <div className='content'>
                    <h3>HTML Tutorial for Beginners | Devsol</h3>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* <section className='section section-1'>
        <div className='shadow_1'>
          <img src={require('../../assets/images/shadow.png')} />
        </div>
        <h2 className='heading'>Trending <span>Courses</span></h2>
        <div className='slider-section'>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3.2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 20,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className='course_card'>
                <div className='icon_box'>
                  <img src={require('../../assets/images/icon.png')} />
                </div>
                <h3>HTML</h3>
                <p>Unlock the world of coding with our comprehensive learning platform.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='course_card'>
                <div className='icon_box'>
                  <img src={require('../../assets/images/icon.png')} />
                </div>
                <h3>HTML</h3>
                <p>Unlock the world of coding with our comprehensive learning platform.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='course_card'>
                <div className='icon_box'>
                  <img src={require('../../assets/images/icon.png')} />
                </div>
                <h3>HTML</h3>
                <p>Unlock the world of coding with our comprehensive learning platform.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='course_card'>
                <div className='icon_box'>
                  <img src={require('../../assets/images/icon.png')} />
                </div>
                <h3>HTML</h3>
                <p>Unlock the world of coding with our comprehensive learning platform.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='course_card'>
                <div className='icon_box'>
                  <img src={require('../../assets/images/icon.png')} />
                </div>
                <h3>HTML</h3>
                <p>Unlock the world of coding with our comprehensive learning platform.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='course_card'>
                <div className='icon_box'>
                  <img src={require('../../assets/images/icon.png')} />
                </div>
                <h3>HTML</h3>
                <p>Unlock the world of coding with our comprehensive learning platform.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='course_card'>
                <div className='icon_box'>
                  <img src={require('../../assets/images/icon.png')} />
                </div>
                <h3>HTML</h3>
                <p>Unlock the world of coding with our comprehensive learning platform.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='course_card'>
                <div className='icon_box'>
                  <img src={require('../../assets/images/icon.png')} />
                </div>
                <h3>HTML</h3>
                <p>Unlock the world of coding with our comprehensive learning platform.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='course_card'>
                <div className='icon_box'>
                  <img src={require('../../assets/images/icon.png')} />
                </div>
                <h3>HTML</h3>
                <p>Unlock the world of coding with our comprehensive learning platform.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='course_card'>
                <div className='icon_box'>
                  <img src={require('../../assets/images/icon.png')} />
                </div>
                <h3>HTML</h3>
                <p>Unlock the world of coding with our comprehensive learning platform.</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section> */}
      <ul className="icons float_icon">
        <li><Link to="https://www.facebook.com/devsolctrla/" target='_blank'><i><FiFacebook /></i></Link></li>
        <li><Link to="https://www.instagram.com/devs_ol/" target='_blank'><i><FiInstagram /></i></Link></li>
        <li><Link to="https://www.linkedin.com/company/dev4sol/" target='_blank'><i><FiLinkedin /></i></Link></li>
      </ul>
      <Footer />
    </>
  )
}
