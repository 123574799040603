import React from 'react';
import { Row, Col } from 'reactstrap';
import Header from '../component/Header';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

function Blog() {
    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title id="pageTitle">Tech Insights and Coding Tips | Devsol Blog</title>
                <link rel="canonical" href="https://www.devsol.in/blogs" />
                <meta name="description"
                    content="Stay updated on the latest tech trends and coding tips with Devsol's blog. Our informative articles cover programming languages, development tools, and industry insights." />
                <meta name="keywords"
                    content="HTML, tutorial, beginner, advanced, web development, coding, programming, learning platform, coding community, problem-solving, freelancing projects, freelance opportunities, programming languages, coding challenges, collaborative coding, coding tutorials, coding resources, coding forums, coding help, coding projects, coding marketplace" />
                <meta name="author" content="Devsol" />
            </Helmet>
            <div className='main'>
                <section className='section section-1'>
                    <h2 className='heading'>Tech <span>Blogs</span></h2>
                    <div className='slider-section-blog'>
                        <Row className='px-3'>
                            <Col md={4}>
                                <Link to={"/blog/html"} className='td-none'>
                                    <div className='course_card'>
                                        <div className='img_box'>
                                            {/* <img src={require('../../assets/images/html.jpg')} /> */}
                                        </div>
                                        <div className='content'>
                                            <h3>HTML Tutorial for Beginners</h3>
                                        </div>
                                        <h2 class="post-card__title"><a href="https://melina.ukathemes.com/demo-1/this-new-hirshhorn-exhibit-will-turn-your-heart-into-art/" rel="bookmark">This new Hirshhorn exhibit will turn your heart into art</a></h2>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}

export default Blog;