import React from 'react'
import { Link } from 'react-router-dom';
import { FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi';
export default function Footer() {
    return (
        <footer>
            <div class="sky">
                <div class="stars"></div>
                <div class="stars1"></div>
                <div class="stars2"></div>
                <div class="shooting_stars"></div>
            </div>
            <ul>
                <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/product">Product</Link>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Privacy-Policy</a>
                </li>
            </ul>
        </footer>
    )
}
